<template>
  <div>
    <b-form-group :label="label" :state="state">
      <template v-if="label" v-slot:label>
        {{ label }}
        <span v-if="required" class="text-danger"> (*) </span>
      </template>
      <v-select
        id="tingee"
        v-model="tingee"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="displayName"
        :options="tingees"
        :placeholder="ignoreCash ? 'Chọn số tài khoản' : 'Danh sách tài khoản'"
        :disabled="!enable"
        @input="tingeeChanged"
        @open="onOpen"
      >
        <template
          #option="{ displayName, accountNumber, vaAccountNumber, isCash }"
        >
          {{ displayName }}
          <br />
          <cite v-if="!isCash">{{
            accountNumber + " - " + vaAccountNumber
          }}</cite>
        </template>

        <!-- eslint-disable-next-line vue/no-unused-vars  -->
        <template #no-options="{ search, searching, loading }">
          Không có dữ liệu
        </template></v-select
      >
      <small class="text-danger">{{ error }}</small>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";
import useJwt from "@/auth/jwt/useJwt";

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    state: {
      type: Boolean,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    defaultValue: {
      type: Object,
      default: null,
    },
    enable: {
      type: Boolean,
      default: true,
    },
    ignoreCash: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tingee: this.defaultValue,
      tingees: [],
    };
  },

  created() {
    this.fetchTingeeAccounts();
  },
  methods: {
    tingeeChanged() {
      this.$emit("input", this.tingee);
    },
    onOpen() {
      if (this.tingees.length === 0) {
        this.fetchApartments();
      }
    },
    fetchTingeeAccounts() {
      const filter = this.location ? { locationId: this.location.id } : {};
      if (this.filterActive) {
        filter.active = this.filterActive;
      }
      useJwt
        .getTingeeAccounts()
        .then((res) => {
          if (this.ignoreCash) {
            this.tingees = res.data.filter((obj) => obj.isCash === false);
          } else {
            this.tingees = res.data;
          }
        })
        .catch(() => {
          this.tingees = [];
        });
    },
    onItemCreated(newItem) {
      this.tingee = newItem;
      this.tingeeChanged();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/vue-select.scss";
.list-header {
  padding: $options-padding-y $options-padding-x;
  color: $secondary;

  &:hover {
    background-color: rgba($success, 0.12);
  }
}
</style>
